import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_a_muse_4.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="八海火鍋-賴珮立 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "位於艋舺西門町的八海鍋物，是韓國明星及自由行背包客來台灣時必定前來造訪的名店，以海鮮鍋美味新鮮、用餐空間衛生乾淨、湯底口味多元和特色明確等主題而立足於鬧區之中。",
            },
            {
              property: `og:title`,
              content: "八海火鍋-賴珮立 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "位於艋舺西門町的八海鍋物，是韓國明星及自由行背包客來台灣時必定前來造訪的名店，以海鮮鍋美味新鮮、用餐空間衛生乾淨、湯底口味多元和特色明確等主題而立足於鬧區之中。歐陽如修",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_4.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;