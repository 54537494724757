import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">八海火鍋<a href="https://www.facebook.com/hakkai2009" className="location" target="_blank">↗</a></h1>
          <h6 className="speaker">店主/賴珮立　筆者/林立青</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_4_a">
            <div className="column_3">
              <div className="context">
                <p>位於艋舺西門町的八海鍋物，是韓國明星及自由行背包客來台灣時必定前來造訪的名店，以海鮮鍋美味新鮮、用餐空間衛生乾淨、湯底口味多元和特色明確等主題而立足於鬧區之中。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_4_b">
            <div className="column_8">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/F6zZdmCfWtA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_4_c">
            <div className="column_3">
              <div className="context">
                <p>八海鍋物過去從大直起家，隨著台北西區的繁華，搬至吃到飽餐廳一級戰區西門町，客層也從原先的台灣客為主，轉型為接受各國觀光客，他們推出具有台灣特色的水產吃到飽鍋物體驗，多數食材堅持使用台灣本土在地漁獲，並在店內聘僱能通韓日語的店長及服務生作為經營手段，使許多旅客不惜提著行李箱也要到店裡一飽口福。</p>
              </div>
            </div>
            <div className="column_3">
              <div className="context">
                <p>要能接待國際觀光客的店舖必須付出足夠努力，除了動線必須明確以外，食材介紹需要有多國語言，同樣也考驗店家的備料以及提供口味的驚喜，且需提供跨國飲品茶點：台灣特色食材和國際知名食材，共同出現才不會發生來客吃膩或者不敢嘗試的情況。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_4_d">
            <div className="column_6">
              <div className="context">
                <p>店主賴珮立，在店內總被稱為珮立姊，勇於嘗試且大膽引進各種方法經營，反映在店內食材上便是敢於在市中心樓上提供現切海產，她做起社會服務也同樣別出心裁，她媒和西門町周遭的旅店將汰換的高級棉被送到芒草心為無家者送暖，招待社區實踐協會社工與輔導兒少前來八海鍋物共餐，她想用自己現有的資源提供給他人，珮立姊會和小朋友一同入席用餐，與社工一起跟孩子們講解吃到飽餐廳的取餐規則及用餐禮儀，這樣的生活經驗具有價值並且極為寶貴，孩子們也能從中得到吃Buffet的經驗，並且創下共同回憶以及話題，也讓社區服務的社工在進行服務時更加得到信任。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_4_e">
            <div className="column_5">
              <div className="context">
                <h6>八海鍋物在當地人眼裡，不只是日韓外國人拍照打卡的聖地，還是社福機構眼裡的模範生，透過參與服務並且認同社工就已經足夠勵志，現在更是人們在討論社區營造時前來學習的對象。</h6>
              </div>
            </div>
          </section>

        </div>

        <div className="page_footer">
          <Link to="/project_a_muse_5" className="next_page">
            <div className="next_title">禾順商旅</div>
            <div className="next_owner">店主/徐舒涵　筆者/林立青</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

